import moment from "moment";
import { useState } from "react";
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";

const ShowAcao = ({ acao }) => {
    const [openInfoAcao, setOpenInfoAcao] = useState(false)

    return (
        <>
            <div className="p-4 sm:px-6 lg:px-8 mt-4 border w-full rounded-[10px] flex justify-between items-center">
                <div className="flex flex-col">
                    <p className="text-sm text-gray-700">Informações completas da ação </p>
                    <button onClick={() => setOpenInfoAcao(!openInfoAcao)} className="flex items-center">
                        <p className="text-sm text-gray-700 mt-2">
                            {
                                openInfoAcao ? "Clique para fechar" : "Clique para abrir"
                            }
                        </p>
                    </button>
                </div>
                <div className="flex flex-col mt-4">
                    <button onClick={() => setOpenInfoAcao(!openInfoAcao)} className="flex items-center justify-between w-full p-2 rounded-[10px]">
                        {!openInfoAcao ? <ArrowUpCircleIcon className="h-7 w-7 text-gray-700" /> : <ArrowDownCircleIcon className="h-7 w-7 text-gray-700" />}
                    </button>
                </div>
            </div>
            {
                openInfoAcao &&
                <div className="p-4 sm:px-6 lg:px-8 mt-4 border rounded-[10px] flex justify-between items-center">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-700">Informações de cadastro</p>


                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 mt-5 ">
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Nome</p>
                                <p className="text-sm text-gray-700 mt-2">{acao.name}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Tribunal</p>
                                <p className="text-sm text-gray-700 mt-2">{acao.court}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Vinculação automática</p>
                                <p className="text-sm text-gray-700 mt-2">
                                    {
                                        acao.auth === 1 ? (
                                            <span className="text-red-500">Não</span>
                                        ) : (
                                            <span className="text-green-500">Sim</span>
                                        )
                                    }
                                </p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Ação interna</p>
                                <p className="text-sm text-gray-700 mt-2">
                                    {
                                        acao.internal === 1 ? (
                                            <span className="text-red-500">Sim</span>
                                        ) : (
                                            <span className="text-green-500">Não</span>
                                        )
                                    }
                                </p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Escritório</p>
                                <p className="text-sm text-gray-700 mt-2">{
                                    acao.lawFirmName ? acao.lawFirmName : "Ação sem escritório"
                                }</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Tipo</p>
                                <p className="text-sm text-gray-700 mt-2">
                                    {
                                        acao.type === 1 ? (
                                            <span className="text-green-500">Ação coletiva</span>
                                        ) : acao.type === 2 ? (
                                            <span className="text-yellow-500">Ação individual</span>
                                        ) : (
                                            <span className="text-blue-400">Ação</span>
                                        )
                                    }
                                </p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Criador</p>
                                <p className="text-sm text-gray-700 mt-2">{acao.email}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Criado em</p>
                                <p className="text-sm text-gray-700 mt-2">{moment(acao.created_at).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700">Restrita por fundo</p>
                                <p className="text-sm text-gray-700 mt-2">
                                    {
                                        acao.isRestricted === 1 ? (
                                            <span className="text-green-500">Sim</span>
                                        ) : (
                                            <span className="text-red-500">Não</span>
                                        )
                                    }
                                </p>
                            </div>
                            {
                                acao.isRestricted === 1 && (
                                    <div className="flex flex-col">
                                        <p className="text-sm text-gray-700">Fundos</p>
                                        {
                                            acao.fundos.map((fundo, index) => {
                                                return (
                                                    <p key={index} className="text-sm text-gray-700 mt-2">{fundo.name}</p>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            }
        </>
    )


}



export default ShowAcao;