import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserIcon, PencilSquareIcon, BanknotesIcon } from '@heroicons/react/24/outline'





const ConntabilIndex = () => {

    const { checkLogin } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-3xl text-center font-bold">Painel Contábil</h1>
                                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso a área contábil do sistema</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">

                                {
                                    (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1' || localStorage.getItem('permission') === '2') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/contabilIndex/debitos" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <PencilSquareIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold">Débitos</h2>
                                            <p className="text-center">Clique nesse cartão para acessar a os débitos presentes</p>
                                        </Link>
                                    </div>
                                }
                                {
                                    (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1' || localStorage.getItem('permission') === '2' || localStorage.getItem('permission') === '5') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/contabilIndex/associados" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <UserIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold">Associados</h2>
                                            <p className="text-center">Clique nesse cartão para acessar os dados de associados</p>
                                        </Link>
                                    </div>
                                }
                                {
                                    (localStorage.getItem('permission') === '0' || localStorage.getItem('permission') === '1') &&
                                    <div className="flex items-center justify-center">
                                        <Link to="/contabilIndex/faturas" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <BanknotesIcon className="h-[30%] w-[30%] text-blue-600" />
                                            <h2 className="text-xl font-bold text-center">Faturas</h2>
                                            <p className="text-center">Clique nesse cartão para acessar os dados de faturas</p>
                                        </Link>
                                    </div>
                                }
                                {/* Aqui precisa de validacoes de permissao */}

                            </div>
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ConntabilIndex;
